<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

input:focus {
  border-width: 1px;
  border-color: #447bba !important;
}
.btn {
  background: #447bba !important;
}

a {
  color: #447bba !important;
  font-size: 16px;
}
a:hover{
  color: #275f90 !important;
}
</style>
