
import Header from './Header.vue'
import { defineComponent } from 'vue'
import { useToast } from 'vue-toast-notification'
import ApiService from '../router/ApiService'

export default defineComponent({
  components: {
    Header,
  },
  name: 'Dashboard',
  data() {
    return {
      statistics: {
        tasks: 0,
        tasks_success: 0,
        tasks_failed: 0,
        proxies: 0,
      },
      task: {
        googleSearch: '',
        blogUrl: '',
        socialUrl: '',
      },
      isLoading: false,
      preLoader: false
    };
  },
  mounted() {
    this.loadStatistics();
  },
  methods: {
    loadStatistics() {
      ApiService.get('/admin/statistics')
          .then(response => {
            this.statistics = response.data.data
            this.preLoader = true
          })
          .catch(error => {
            console.error('Error loading statistics:', error)
          });
    },
    async submitForm() {
      if (!this.task.googleSearch || !this.task.blogUrl || !this.task.socialUrl) {
        this.showErrorToast('All fields are required.')
        return
      }

      if (!isUrlValid(this.task.blogUrl) || !isUrlValid(this.task.socialUrl)) {
        this.showErrorToast('Invalid format url.')
        return
      }

      this.isLoading = true

      try {
        const response = await ApiService.post('/admin/add_task', {
          task: {
            search: this.task.googleSearch,
            blog_url: this.task.blogUrl,
            social_url: this.task.socialUrl,
          },
        });

        if (response.data.status == 'ok' && response.data.data) {
          // Clear the form inputs
          this.task.googleSearch = '';
          this.task.blogUrl = '';
          this.task.socialUrl = '';

          this.showSuccessToast('Task successful added to working.')

          // update statistics counts
          this.loadStatistics()
        } else {
          this.showErrorToast('An error occurred while adding the task! Try again later')
        }
      } catch (error) {
        this.showErrorToast('An error occurred while adding the task! Try again later')
      }

      this.isLoading = false
    },
    showErrorToast(message: string) {
      const toast = useToast()
      toast.error(message, {'position': 'top-right', 'duration': 5000})
    },
    showSuccessToast(message: string) {
      const toast = useToast()
      toast.success(message, {'position': 'top-right', 'duration': 5000})
    },
  },
});

function isUrlValid(url: string) {
  // Add your URL validation logic here
  // Example: Check if the URL is valid using a regular expression
  const urlRegex = /^(http(s)?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
  return urlRegex.test(url);
}
