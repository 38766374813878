
import { defineComponent, ref } from 'vue'
import moment from 'moment-timezone'
import Header from './Header.vue'
import ApiService from "@/router/ApiService"
import { useToast } from "vue-toast-notification"
import 'prismjs/themes/prism.css';
import 'prismjs';
import 'prismjs/components/prism-json';
import Modal from './Modal.vue'

export default defineComponent({
  components: {
    Header,
    Modal,
  },
  name: "TaskDetailView",
  data() {
    return {
      task: null,
      taskID: 0 as any,
      progresses: [],
      isLoading: false,
      currentPage: 0,
      totalPages: 0,
      hasNextPage: false,
    };
  },
  setup() {
    const showPopup = ref(false)
    const jsonData = ref('')

    const openPopup = (data: any): void => {
      // Set your JSON data to be displayed
      jsonData.value = JSON.stringify(data, null, 2)
      showPopup.value = true
    };

    const closePopup = (): void => {
      showPopup.value = false
    };

    return {
      showPopup,
      jsonData,
      openPopup,
      closePopup,
    };
  },
  mounted() {
    this.taskID = this.$route.params.id
    this.loadTask()
    this.loadProgress()
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    getCodeClass(code: any) {
      return 'language-json'
    },
    loadTask() {
      ApiService.get(`/admin/tasks/get/${this.taskID}`)
          .then(response => {
            if (response.data.status === 'ok') {
              this.task = response.data.data
            } else {
              this.showErrorToast(response.data.message)
              this.$router.push('/')
            }
          })
          .catch(error => {
            this.showErrorToast('Error loading task.')
            this.$router.push('/')
          })
    },
    loadProgress() {
      ApiService.get(`/admin/tasks/detail/${this.taskID}`)
          .then(response => {
            this.progresses = response.data.data.progress

            // Update the pagination information
            this.currentPage = response.data.data.pages.current_page
            this.totalPages = response.data.data.pages.total_pages
            this.hasNextPage = response.data.data.pages.load_more
          })
          .catch(error => {
            this.showErrorToast('Error loading task progress.')
          })
    },
    async loadMoreProgress() {
      // Set the isLoading flag to true while loading tasks
      this.isLoading = true

      // Make the API request to fetch tasks for the next page
      ApiService.get(`/admin/tasks/detail/${this.taskID}?page=${this.currentPage + 1}`)
          .then(response => {
            // Append the new tasks to the existing list
            this.progresses = this.progresses.concat(response.data.data.progress)

            // Update the pagination information
            this.currentPage = response.data.data.pages.current_page
            this.totalPages = response.data.data.pages.total_pages
            this.hasNextPage = response.data.data.pages.load_more

            // Set the isLoading flag to false after loading more tasks
            this.isLoading = false
          })
          .catch(error => {
            this.showErrorToast('Error load more tasks! Try again.')
            this.isLoading = false
          })
    },
    formattedDateTime(datetime: Date) {
      if (datetime) {
        const dateTime = moment(datetime).tz('Europe/Warsaw')
        return dateTime.format('DD.MM.YYYY HH:mm')
      }
      return 'in queue'
    },
    getStatusColor(status: string) {
      switch (status) {
        case 'completed':
          return 'text-green-500';
        case 'failed':
          return 'text-red-500';
        default:
          return 'text-black';
      }
    },
    deleteTask(taskID: number) {
      if (confirm("Are you sure you want to delete this task?")) {
        ApiService.get('/admin/tasks/delete/' + taskID)
            .then(response => {
              if (response.data.status == 'ok') {
                this.showSuccessToast('Task delete successful.')
                this.$router.push('/tasks')
              } else {
                this.showErrorToast(response.data.message)
              }
            })
            .catch((error) => {
              this.showErrorToast('An error occurred while deleting the task! Try again later')
            })
      }
    },
    handleScroll() {
      const scrollPosition = window.innerHeight + window.pageYOffset
      const pageHeight = document.documentElement.scrollHeight

      if (scrollPosition >= pageHeight && this.hasNextPage && !this.isLoading) {
        this.loadMoreProgress()
      }
    },
    showSuccessToast(message: string) {
      const toast = useToast()
      toast.success(message, {'position': 'top-right', 'duration': 3000})
    },
    showErrorToast(message: string) {
      const toast = useToast()
      toast.error(message, {'position': 'top-right', 'duration': 3000})
    },
  }
})
