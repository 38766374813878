
import Header from './Header.vue'
import { defineComponent } from 'vue'
import { useToast } from 'vue-toast-notification'
import ApiService from '../router/ApiService'
export default defineComponent({
  components: {
    Header,
  },
  name: "SettingsView",
  data() {
    return {
      password: '',
      new_password: '',
      retry_password: '',
      errorMessage: '',
    }
  },
  methods: {
    async submitForm() {
      if (!this.password || !this.new_password || !this.retry_password) {
        this.showErrorToast('All fields are required.')
        return
      }

      if (this.password.length < 6 || this.new_password.length < 6 || this.retry_password.length < 6) {
        this.showErrorToast('Password must be at least 6 characters long.')
        return
      }

      if (this.new_password !== this.retry_password) {
        this.showErrorToast('New passwords do not match')
        return
      }

      try {
        const response = await ApiService.post('/admin/change_password', {
          password: this.password,
          new_password: this.new_password,
          retry_password: this.retry_password
        });

        if (response.data.status == 'ok') {
          // Clear the form inputs
          this.password = '';
          this.new_password = '';
          this.retry_password = '';

          this.showSuccessToast('Password successful changed.')
        } else {
          this.showErrorToast('An error occurred while adding the task! Try again later.')
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          // Display specific error message for incorrect password
          this.errorMessage = error.response.data.message
        } else {
          // Show generic error message
          this.errorMessage = 'Login failed. Please try again.'
        }
      }

      // Show error toast if there is an error message
      if (this.errorMessage) {
        this.showErrorToast(this.errorMessage)
        this.errorMessage = '' // Reset error message
      }
    },
    showErrorToast(message: string) {
      const toast = useToast()
      toast.error(message, {'position': 'top-right', 'duration': 3000})
    },
    showSuccessToast(message: string) {
      const toast = useToast()
      toast.success(message, {'position': 'top-right', 'duration': 3000})
    },
  }
})
