
import { defineComponent } from 'vue'
import Header from './Header.vue'
import { useToast } from 'vue-toast-notification'
import ApiService from "@/router/ApiService";
export default defineComponent({
  components: {
    Header,
  },
  name: 'UsersView',
  data() {
    return {
      users: [], // Users data array
      invitingLinks: [],
      currentPage: 1, // Current page number
      itemsPerPage: 10, // Number of items per page
      logged_amin_id: null,
      link_host: '',
    }
  },
  // computed: {
  //   paginatedUsers() {
  //     const startIndex = (this.currentPage - 1) * this.itemsPerPage
  //     const endIndex = startIndex + this.itemsPerPage
  //     return this.users.slice(startIndex, endIndex)
  //   },
  // },
  methods: {
    adminLoggedID() {
      const user = JSON.parse(localStorage.getItem('user') || '{}')
      this.logged_amin_id = user && user.id
    },
    fetchUsers() {
      ApiService.get('/admin/users/get')
          .then(response => {
            this.users = response.data.data.users;
          })
          .catch(error => {
            console.error('Error loading users:', error);
          });
    },
    fetchInvites() {
      ApiService.get('/admin/invite/get')
          .then(response => {
            this.invitingLinks = response.data.data;
          })
          .catch(error => {
            console.error('Error loading invite links:', error);
          });
    },
    addInviteLink(role: string) {
      ApiService.get('/admin/invite/created/' + role)
          .then(response => {
            if (response.data.status == 'ok') {
              this.showSuccessToast('Invite link successful created.')

              // update invite links
              this.fetchInvites();
            } else {
              this.showErrorToast(response.data.message)
            }
          })
          .catch((error) => {
            this.showErrorToast('An error occurred while adding the task! Try again later')
          })
    },
    deleteInviteLink(inviteId: any) {
      if (confirm("Are you sure you want to delete this invite link?")) {
        ApiService.get('/admin/invite/delete/' + inviteId)
            .then(response => {
              if (response.data.status == 'ok') {
                this.showSuccessToast('Invite link delete successful.')

                // update list user
                this.fetchInvites();
              } else {
                this.showErrorToast(response.data.message)
              }
            })
            .catch((error) => {
              this.showErrorToast('An error occurred while adding the task! Try again later')
            })
      }
    },
    deleteUserById(userId: any) {
      if (confirm("Are you sure you want to delete this user?")) {
        // Perform delete user logic here based on the user's ID
        // Example API call using ApiService:
        ApiService.get('/admin/users/delete/' + userId)
            .then(response => {
              if (response.data.status == 'ok') {
                this.showSuccessToast('User delete successful.')

                // update list user
                this.fetchUsers();
              } else {
                this.showErrorToast(response.data.message)
              }
            })
            .catch((error) => {
              this.showErrorToast('An error occurred while adding the task! Try again later')
            });
      }
    },
    showErrorToast(message: string) {
      const toast = useToast()
      toast.error(message, {'position': 'top-right', 'duration': 3000})
    },
    showSuccessToast(message: string) {
      const toast = useToast()
      toast.success(message, {'position': 'top-right', 'duration': 3000})
    },
  },
  // Fetch users data or set it from local storage
  mounted() {
    this.link_host = location.protocol + '//' + location.host

    // set logged admin id
    this.adminLoggedID()

    // Fetch users data from API or set it from local storage
    this.fetchUsers()

    // Fetch inviting links
    this.fetchInvites()
  },
})
