
import { defineComponent } from 'vue'
import ApiService from "../router/ApiService";
import {useToast} from "vue-toast-notification";
export default defineComponent({
  data() {
    return {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      inviteKey: '',
      isLoading: false,
      errorMessage: '',
    };
  },
  mounted() {
    // Get the invite key from the route parameter
    this.inviteKey = this.$route.params.invite_key as string

    // check invite
    this.checkInvite()
  },
  methods: {
    checkInvite() {
      ApiService.get('/admin/invite/check/' + this.inviteKey)
          .then(response => {
            if (response.data.status === 'fail') {
              this.$router.push('/')
            }
          })
          .catch(error => {
            console.error('Error loading invite links:', error);
          })
    },
    async submitForm() {
      if (!this.email || !this.password) {
        this.showErrorToast('All fields are required.')
        return
      }

      if (!this.isValidEmail(this.email)) {
        this.showErrorToast('Please enter a valid email address.')
        return
      }

      if (this.password.length < 6 || this.confirmPassword.length < 6) {
        this.showErrorToast('Password must be at least 6 characters long.')
        return
      }

      if (this.password !== this.confirmPassword) {
        this.showErrorToast('Password and confirm password do not match')
        return
      }

      this.isLoading = true

      try {
        const response = await ApiService.post('/admin/invite/register/' + this.inviteKey, {
          name: this.name,
          email: this.email,
          password: this.password,
          confirm_password: this.confirmPassword
        })

        if (response.data.status == 'ok' && response.data.auth == true) {
          // Store login token and expiration in browser storage
          localStorage.setItem('authToken', response.data.token)
          localStorage.setItem('user', JSON.stringify(response.data.data.user))

          // Clear form fields
          this.name = ''
          this.email = ''
          this.password = ''
          this.confirmPassword = ''

          // Show success toast
          this.showSuccessToast('Registration in successfully!')

          this.$router.push('/dashboard')
        } else {
          this.showErrorToast('Registration failed. Please try again.')
        }
      } catch (error: any) {
        console.log(error)
        if (error.response && error.response.status === 400) {
          // Display specific error message for incorrect password
          this.errorMessage = error.response.data.message
        } else {
          // Show generic error message
          this.errorMessage = 'Registration failed. Please try again.'
        }
      }

      // Show error toast if there is an error message
      if (this.errorMessage) {
        this.showErrorToast(this.errorMessage)
        this.errorMessage = '' // Reset error message
      }

      this.isLoading = false
    },
    isValidEmail(email: string): boolean {
      // Basic email validation regex
      const emailRegex = /^\S+@\S+\.\S+$/
      return emailRegex.test(email)
    },
    showErrorToast(message: string) {
      const toast = useToast()
      toast.error(message, {'position': 'top-right', 'duration': 3000})
    },
    showSuccessToast(message: string) {
      const toast = useToast()
      toast.success(message, {'position': 'top-right', 'duration': 3000})
    },
  },
})
