
import { defineComponent } from 'vue'
import Header from './Header.vue'
import ApiService from "@/router/ApiService"
import { useToast } from "vue-toast-notification"
import moment from 'moment-timezone';

export default defineComponent({
  components: {
    Header,
  },
  name: 'Tasks',
  data() {
    return {
      tasks: [],
      searchTerm: '',
      filteredTasks: [],
      isDropdownOpen: false,
      noResults: false,
      searchTimeout: 0,
      isLoading: false,
      currentPage: 0,
      totalPages: 0,
      hasNextPage: false,
    };
  },
  methods: {
    formattedDateTime(datetime: Date) {
      if (datetime) {
        const dateTime = moment(datetime).tz('Europe/Warsaw')
        return dateTime.format('DD.MM.YYYY HH:mm')
      }
      return 'in queue'
    },
    showDetails(task_id: number) {
      this.$router.push(`/tasks/detail/${task_id}`)
    },
    async searchTasks() {
      this.noResults = false
      clearTimeout(this.searchTimeout)
      if (this.searchTerm == '') {
        this.clearSearch()
      } else {
        this.searchTimeout = setTimeout(async () => {
          try {
            ApiService.get(`/admin/tasks/search?q=${this.searchTerm}`)
                .then(response => {
                  this.filteredTasks = response.data.data
                  this.isDropdownOpen = true

                  // check none results
                  if (this.filteredTasks.length === 0) {
                    this.noResults = true
                  }
                })
                .catch(error => {
                  console.error('Error loading tasks:', error)
                })
          } catch (error) {
            console.error(error)
          }
        }, 1000)
      }
    },
    clearSearch() {
      this.searchTerm = ''
      this.filteredTasks = []
      this.isDropdownOpen = false
      this.noResults = false
    },
    fetchTasks() {
      ApiService.get('/admin/tasks/get')
          .then(response => {
            this.tasks = response.data.data.tasks
            this.currentPage = response.data.data.pages.current_page
            this.totalPages = response.data.data.pages.total_pages
            this.hasNextPage = response.data.data.pages.load_more
          })
          .catch(error => {
            console.error('Error loading tasks:', error)
          })
    },
    async loadMoreTasks() {
      // Set the isLoading flag to true while loading tasks
      this.isLoading = true

      // Make the API request to fetch tasks for the next page
      ApiService.get(`/admin/tasks/get?page=${this.currentPage + 1}`)
          .then(response => {
            // Append the new tasks to the existing list
            this.tasks = this.tasks.concat(response.data.data.tasks)

            // Update the pagination information
            this.currentPage = response.data.data.pages.current_page
            this.totalPages = response.data.data.pages.total_pages
            this.hasNextPage = response.data.data.pages.load_more

            // Set the isLoading flag to false after loading more tasks
            this.isLoading = false
          })
          .catch(error => {
            this.showErrorToast('Error load more tasks! Try again.')
            this.isLoading = false
          })
    },
    deleteTask(taskID: number) {
      if (confirm("Are you sure you want to delete this task?")) {
        ApiService.get('/admin/tasks/delete/' + taskID)
            .then(response => {
              if (response.data.status == 'ok') {
                this.showSuccessToast('Task delete successful.')
                this.tasks = this.tasks.filter((el: { id: number }) => el.id !== taskID)
              } else {
                this.showErrorToast(response.data.message)
              }
            })
            .catch((error) => {
              this.showErrorToast('An error occurred while deleting the task! Try again later')
            })
      }
    },
    handleScroll() {
      const scrollPosition = window.innerHeight + window.pageYOffset
      const pageHeight = document.documentElement.scrollHeight

      if (scrollPosition >= pageHeight && this.hasNextPage && !this.isLoading) {
        this.loadMoreTasks()
      }
    },
    showSuccessToast(message: string) {
      const toast = useToast()
      toast.success(message, {'position': 'top-right', 'duration': 3000})
    },
    showErrorToast(message: string) {
      const toast = useToast()
      toast.error(message, {'position': 'top-right', 'duration': 3000})
    },
  },
  mounted() {
    this.fetchTasks()
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
})
