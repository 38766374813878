
import { defineComponent } from 'vue'
import Header from './Header.vue'
import { useToast } from 'vue-toast-notification'
import ApiService from "@/router/ApiService";

export default defineComponent({
  components: {
    Header,
  },
  name: 'ProxyManager',
  data() {
    return {
      proxies: [],
      showProxyForm: false,
      username: '',
      password: '',
      proxy: '',
      type: 'ipv4',
      isLoading: false,
      loading: true,
    };
  },
  methods: {
    fetchProxies() {
      // start loading proxy
      this.loading = true

      // loading with server api
      ApiService.get('/admin/proxy/get')
          .then(response => {
            this.proxies = response.data.data
            this.loading = false
          })
          .catch(error => {
            console.error('Error loading proxies:', error);
          });
    },
    reCheckProxy(proxyId: any, proxy: any) {
      ApiService.get('/admin/proxy/re-check/' + proxyId)
          .then(response => {
            if (response.data.status == 'ok') {
              this.showSuccessToast('Proxy `' + proxy + '` now is active.')
              this.fetchProxies()
            } else {
              this.showErrorToast('Proxy `' + proxy + '` not working.')
            }
          })
          .catch((error) => {
            this.showErrorToast('An error occurred while adding the proxy! Try again later')
          })
    },
    deleteProxy(proxyId: any) {
      if (confirm("Are you sure you want to delete this proxy?")) {
        ApiService.get('/admin/proxy/delete/' + proxyId)
            .then(response => {
              if (response.data.status == 'ok') {
                this.showSuccessToast('Proxy delete successful.')
                this.fetchProxies()
              } else {
                this.showErrorToast(response.data.message)
              }
            })
            .catch((error) => {
              this.showErrorToast('An error occurred while deleting the proxy! Try again later')
            })
      }
    },
    async submitForm() {
      if (!this.proxy) {
        this.showErrorToast('Field proxy are required.')
        return
      }

      this.isLoading = true

      try {
        const response = await ApiService.post('/admin/proxy/add', {
          username: this.username,
          password: this.password,
          proxy: this.proxy,
          type: this.type
        });

        if (response.data.status == 'ok') {
          // Clear the form inputs
          this.username = ''
          this.password = ''
          this.proxy = ''

          this.showSuccessToast('Proxy successful add.')
          this.showProxyForm = false

          // update statistics counts
          this.fetchProxies()
        } else {
          this.showErrorToast(response.data.message)
        }
      } catch (error) {
        this.showErrorToast('An error occurred while adding the task! Try again later')
      }

      this.isLoading = false
    },
    showErrorToast(message: string) {
      const toast = useToast()
      toast.error(message, {'position': 'top-right', 'duration': 3000})
    },
    showSuccessToast(message: string) {
      const toast = useToast()
      toast.success(message, {'position': 'top-right', 'duration': 3000})
    },
  },
  mounted() {
    // load proxies
    this.fetchProxies()
  }
})
