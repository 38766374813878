
import { defineComponent } from 'vue'
import { useToast } from 'vue-toast-notification'
import ApiService from '../router/ApiService'

export default defineComponent({
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
      errorMessage: '',
    }
  },
  methods: {
    async submitForm() {
      if (!this.email || !this.password) {
        this.showErrorToast('All fields are required.')
        return
      }

      if (!this.isValidEmail(this.email)) {
        this.showErrorToast('Please enter a valid email address.')
        return
      }

      if (this.password.length < 6) {
        this.showErrorToast('Password must be at least 6 characters long.')
        return
      }

      this.isLoading = true

      try {
        const response = await ApiService.post('/login', {
          email: this.email,
          password: this.password,
        })

        if (response.data.status == 'ok' && response.data.auth == true) {
          // Store login token and expiration in browser storage
          localStorage.setItem('authToken', response.data.token)
          localStorage.setItem('user', JSON.stringify(response.data.data.user))

          // Clear form fields
          this.email = ''
          this.password = ''

          // Show success toast
          this.showSuccessToast('Logged in successfully!')

          // Redirect to dashboard
          // if (response.data.new_user == false) {
          //   this.$router.push('/dashboard')
          // } else {
          //   this.$router.push('/settings')
          // }
          this.$router.push('/dashboard')
        } else {
          this.showErrorToast('Login failed. Please try again.')
        }
      } catch (error: any) {
        console.log(error)
        if (error.response && error.response.status === 400) {
          // Display specific error message for incorrect password
          this.errorMessage = error.response.data.message
        } else {
          // Show generic error message
          this.errorMessage = 'Login failed. Please try again.'
        }
      }

      // Show error toast if there is an error message
      if (this.errorMessage) {
        this.showErrorToast(this.errorMessage)
        this.errorMessage = '' // Reset error message
      }

      this.isLoading = false
    },
    isValidEmail(email: string): boolean {
      // Basic email validation regex
      const emailRegex = /^\S+@\S+\.\S+$/
      return emailRegex.test(email)
    },
    showErrorToast(message: string) {
      const toast = useToast()
      toast.error(message, {'position': 'top-right', 'duration': 3000})
    },
    showSuccessToast(message: string) {
      const toast = useToast()
      toast.success(message, {'position': 'top-right', 'duration': 3000})
    },
  },
})
