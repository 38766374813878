
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Header',
  data() {
    return {
      showUserMenu: false,
      showMobileMenu: false,
      user: {},
    };
  },
  computed: {
    userRole() {
      const user = JSON.parse(localStorage.getItem('user') || '{}')
      return user && user.role;
    },
  },
  methods: {
    adminLoggedAdmin() {
      this.user = JSON.parse(localStorage.getItem('user') || '{}')
    },
    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu
    },
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu
    },
    logout() {
      localStorage.removeItem('authToken')
      localStorage.removeItem('user')
      this.$router.push('/')
    },
  },
  mounted() {
    this.adminLoggedAdmin()
  }
})
