import axios from 'axios'

const ApiService = axios.create({
    baseURL: '/api/v1', // Replace with your API base URL
    headers: {
        'Content-Type': 'application/json',
    },
})

ApiService.interceptors.request.use((config) => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

ApiService.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Unauthorized error (status code 401)
            // Delete token and redirect to login
            localStorage.removeItem('authToken')
            window.location.href = '/login'
        }
        return Promise.reject(error)
    }
)

export default ApiService