import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import AboutView from '../views/AboutView.vue'
import SettingsView from '../views/SettingsView.vue';
import UsersView from '../views/UsersView.vue';
import InvitingRegisterView from '../views/InvitingRegisterView.vue';
import ProxyManagerView from '../views/ProxyManagerView.vue';
import TasksView from '../views/TasksView.vue';
import TaskDetailView from '../views/TaskDetailView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: AboutView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    component: UsersView,
    meta: {
      requiresAuth: true,
      requiredRoles: ['administrator'],
    },
  },
  {
    path: '/invite/register/:invite_key',
    name: 'invite-register',
    component: InvitingRegisterView,
    meta: {
      requiresAuth: false, // Set requiresAuth to false for the route
    },
  },
  {
    path: '/proxy-manager',
    name: 'proxy-manager',
    component: ProxyManagerView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: TasksView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tasks/detail/:id',
    name: 'task_detail',
    component: TaskDetailView,
    meta: {
      requiresAuth: true
    }
  },
  {path: '/:catchAll(.*)', redirect: '/'}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Navigation guard to check authentication status
router.beforeEach((to, from, next) => {
  const authToken = localStorage.getItem('authToken')
  const requiredRoles = (to.meta as { requiredRoles?: string[] }).requiredRoles || []

  if (to.meta.requiresAuth && !authToken) {
    // User is not authenticated, redirect to the login page
    next({name: 'login'})
  } else if (to.meta.requiresAuth === false && authToken) {
    next({name: 'dashboard'})
  } else if (to.name === 'login' && authToken) {
    // If the user is authenticated and trying to access the login page
    next({ name: 'dashboard' }) // Redirect to the dashboard page
  } else if (requiredRoles.length > 0 && !hasRequiredRole(requiredRoles)) {
    next({ name: 'login' })
  } else {
    // User is authenticated or accessing a public route
    next()
  }
})

function hasRequiredRole(requiredRoles: string | any[]) {
  const user = JSON.parse(<string>localStorage.getItem('user'));
  return user && requiredRoles.includes(user.role);
}

export default router
